
        import { defineComponent, ref, Ref, watch, defineProps } from "vue";
        import { IUserData } from "@/store/UsersStore";
        import axios from "axios";
        import useAlert from "@/composables/Alert";
    
        export default defineComponent({
            name: "modalRelation",
            props:{
                relationData: Object,
                isChangeProps: Boolean
            
            },
            setup(props) {
                const modalRelationInfo = ref()
                const relationDataInfo = ref<any | never>([])
                const ownRelationInfo = ref()           
                const value1 = ref([])
                const options = ref<any>([])
                const isLoading = ref(false)
                const isLoadingButton = ref(false)
                const isLoadSelect = ref(false)
                const lenghtRealation = ref(0)
    
                function resetSelectValues() {
                    value1.value = []
                    lenghtRealation.value = 0
                }
    
                const { showTimeAlert } = useAlert()
             
    
    
                async function onSubmitFunction(data) {
                    isLoading.value = true
                    try {
                    const arrayRelation: any = []
                        value1.value.forEach(elem => {
                            arrayRelation.push({
                                id_profile: ownRelationInfo.value.id,
                                id_store: elem
                            })
                        })
                   
                        const response = await axios.post('/api/postStoreProfile', arrayRelation)
    
                        await getRelation()
                         geDataRelation()
                         
                        } catch (error) {
                            if(error instanceof Error) {
                                
                                showTimeAlert(error.message, "error")
                            }
                            
                        } finally {
                            isLoading.value = false
                            resetSelectValues()
                        lenghtRealation.value = relationDataInfo.value.length
                    }
                }
    
                async function getRelation(loading: boolean | undefined = true) {
                    if(loading){
                        isLoading.value = true
                    }
                    isLoadingButton.value = true
                    try {
                        const response = await axios.get(`/api/getStoreProfile/${ownRelationInfo.value.id}`)
                        relationDataInfo.value = response.data.data
                    } catch (error) {
                      if(error instanceof Error) {
    
                        showTimeAlert(error.message, "error")
                      }
                    } finally {
                        isLoading.value = false
                        isLoadingButton.value = false
                        lenghtRealation.value = relationDataInfo.value.length
                    }
                }
    
                watch(
                    () => props.isChangeProps,
                    () => {
                        ownRelationInfo.value = props.relationData
                        getRelation()
                        geDataRelation()
                    }
                );  
    
                async function geDataRelation() {
                    isLoadSelect.value = true
                    options.value = []
                    try {
                        const response = await axios.get('/api/getStore')
                        modalRelationInfo.value = response.data.data
                        console.log("Dados",modalRelationInfo.value )
                        modalRelationInfo.value.forEach(element => {       
                            if(!relationDataInfo.value.some(elem => elem.id === element.id)){
                                options.value.push({
                                    value: element.id,
                                    label: element.fantasy_name
                                })
                           }    
    
                        
                    });
                } catch (error) {
                    isLoadSelect.value = false
                    if(error instanceof Error) {
                        
                        showTimeAlert(error.message, "error")
                    }
                }finally{
                    isLoadSelect.value = false
                }
                }
            
      
                async function deletRelation(idStore){
                    isLoadingButton.value = true
                    try {
                        const response = await axios.delete(`/api/deleteStoreProfile/${idStore}`)
                        getRelation(false)
                        geDataRelation()
                       
                    } catch (error) {
                      if(error instanceof Error) {
                        showTimeAlert(error.message, "error")
                      }
    
                    } finally {
                        // isLoadingButton.value = false
                    }
                }
          
    
    
           
                
                return {
                    modalRelationInfo,
                    ownRelationInfo,
                    value1,
                    options,
                    onSubmitFunction,
                    relationDataInfo,
                    resetSelectValues,
                    deletRelation,
                    isLoading,
                    isLoadSelect,
                    isLoadingButton,
                    lenghtRealation
                }
            }
    
        })
    
    